/*ディスプレイ初期値設定*/
$bp_sp_ipse: 320px;
$bp_sp: 767px;
$bp_tb: 1024px;
$bp_pc_s: 1280px;
$bp_pc_m: 1366px;
$bp_pc_l: 1600px;
$bp_pc_ll: 1920px;


/*以下、サイト固有の設定*/

//カスタムフィールド設置するまでの仮
$main_color: #3F51B5;

$accent_color: #2196F3;


$sub_color01: #222222;
//$sub_color02: #01786E;
//$sub_color03: #FF8F00;

//$blue_color01: #02214D;
//$red_color01: #DC000C;

/*
$gray_color01: #D1D1D1;
$gray_color02: #ccc;
$gray_color03: #777;
$gray_color04: #555;
$gray_color05: #F4F3F3;
$gray_color06: #ddd;
*/

//$white_color: #fff;


$text_color01: #222;
$text_color02: #333;
$text_gray01: #bbb;
$text_gray02: #616161;

$pc-font_size: 16px;
$sp-font_size: 14px;

$content_width: 1200px;

$radius_size1: 10px;
// $radius_size2: 5px;

/*サイト共通の間隔*/
$pc_sp1: 150px;
$pc_sp2: 100px;
$pc_sp3: 70px;
$pc_sp4: 50px;
$pc_sp5: 30px;
$pc_sp6: 20px;

$tb_sp1: 75px;
$tb_sp2: 50.1px;
$tb_sp3: 50px;
$tb_sp4: 30px;
$tb_sp5: 20.1px;
$tb_sp6: 20px;

$sp_sp1: 20vw;//横幅375pxの時の75px
$sp_sp2: 13.331vw;//横幅375pxの時の50px
$sp_sp3: 13.33vw;//横幅375pxの時の50px
$sp_sp4: 8vw;//横幅375pxの時の30px
$sp_sp5: 5.331vw;//横幅375pxの時の20px
$sp_sp6: 5.33vw;//横幅375pxの時の20px

$basic_space_pc : $pc_sp1,$pc_sp2,$pc_sp3,$pc_sp4,$pc_sp5,$pc_sp6;
$basic_space_tb : $tb_sp1,$tb_sp2,$tb_sp3,$tb_sp4,$tb_sp5,$tb_sp6;
$basic_space_sp : $sp_sp1,$sp_sp2,$sp_sp3,$sp_sp4,$sp_sp5,$sp_sp6;

$content_space_pc : $pc_sp1;
$content_space_tb : $tb_sp1;
$content_space_sp : $sp_sp1;

$header_height_pc: 100px;
$header_height_sp: 70px;
$gnav_height_sp: 60px;

$basic_transition: 0.3s ease-out;
$hv_common_transition: 0.6s cubic-bezier(0.190, 1.000, 0.220, 1.000);
$hv_fill_transition: 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);


/*英語用font読み込み指定*/
/*
@font-face {
	font-family: 'General Sans';
	src: url("../font/GeneralSans-Regular.otf") format("otf");
    font-weight: normal;
    font-style: normal;
}
@font-face {
	font-family: 'General Sans';
	src: url("../font/GeneralSans-Semibold.otf") format("otf");
    font-weight: bold;
    font-style: normal;
}
*/

$gothic: YakuHanJP_Noto, "Noto Sans JP", "游ゴシック体", "Yu Gothic", YuGothic,'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
//$gothic: YakuHanJP, "游ゴシック体", "Yu Gothic", YuGothic,'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
$mincho: YakuHanMP, "游明朝","Yu Mincho","YuMincho", 'メイリオ', Meiryo, serif;
//$eng_font: 'General Sans', sans-serif;
$eng_font: 'Montserrat', sans-serif;


@mixin max_scr($break_point) { 
	@media screen and (max-width: $break_point) {
		@content;
	}
}
@mixin min_scr($break_point) { 
	@media screen and (min-width: ($break_point + 1)) {
		@content;
	}
}
@mixin min_scr_pri($break_point) { 
	@media print,screen and (min-width: $break_point) {
		@content;
	}
}
@mixin min_max_scr($break_point_min, $break_point_max) { 
	@media screen and (min-width: ($break_point_min + 1)) and (max-width: $break_point_max) {
		@content;
	}
}
