@charset "utf-8";

//scss基本設定
@import "./_variables.scss";
//scss基本設定end

$px_size:0,5px,10px,15px,20px,25px,30px,45px,50px;

$padding_margin: 'padding','margin';

$direction:(
	t_:top,
	r_:right,
	b_:bottom,
	l_:left
);


$disp_size:(
	sp:'max-width:767px',
	pc:'min-width:768px'
);

/* CSS Document */
.word_break_all{word-break: break-all!important}
.float_left{float:left!important}
.float_right{float:right!important}
.clear_both{clear:both!important}

/*　ボーダー関連　*/
.border_none{border: none!important}
.border_gray{border: 1px solid #ddd!important}
.border_top_gray{border-top: 1px solid #ddd!important}
.border_left_gray{border-left: 1px solid #ddd!important}
.border_right_gray{border-right: 1px solid #ddd!important}
.border_bottom_gray{border-bottom: 1px solid #ddd!important}

/*　文字色　*/
.color_black{color: #222!important}
.color_gray{color: #ddd!important}
.color_white{color: #fff!important}
.color_red{color: #FF0000!important}
.color_green{color: #478393!important}
.color_yellow{color: #FFF000!important}

/*　文字装飾　*/
.text_deco_underline{text-decoration: underline!important}

/*　display関連　*/
.display_block{display:block!important}
.display_none{display:none!important}
.display_inlineblock{display:inline-block!important}
	
/*flex関連　*/
.display_flex{display: flex!important}
.justify_start{justify-content: flex-start!important}
.justify_center{justify-content: center!important}
.justify_end{justify-content: flex-end!important}
.justify_around{justify-content: space-around!important}
.justify_between{justify-content: space-between!important}
.align_items_start{align-items: flex-start!important}
.align_items_center{align-items: center!important}
.align_items_end{align-items: flex-end!important}
.flex_wrap_wrap{flex-wrap: wrap!important}
.flex_wrap_nowrap{flex-wrap: nowrap!important}

/*　その他　*/
.pointer_none{pointer-events: none!important}
.overflow_hidden{overflow:hidden!important}
.overflow_auto{overflow:auto!important}
.overflow_xscroll{overflow-x: scroll!important}
.white_space_nowrap{white-space:nowrap!important}
.white_space_normal{white-space:normal!important}
.align_item_flex_start{align-items: flex-start!important}
.align_item_center{align-items: center!important}
.align_item_flex_end{align-items: flex-end!important}

/*　position　*/
.position_static{position: static!important}
.position_relative{position: relative!important}
.position_absolute{position: absolute!important}
.position_fixed{position: fixed!important}


/*　margin padding　*/
@each $px_val in $px_size {
    .ma_#{$px_val} {
		margin:$px_val!important
	}
}
@each $dir_key, $dir_value in $direction {
	@each $px_val in $px_size {
		.ma_#{$dir_key}#{$px_val} {
			margin-#{$dir_value}:$px_val!important
		}
		.pd_#{$dir_key}#{$px_val} {
			padding-#{$dir_value}:$px_val!important
		}
	}
	@for $i from 1 through 10{
		.ma_#{$dir_key}#{$i}em {
			margin-#{$dir_value}:#{$i}em!important
		}
		.ma_#{$dir_key}#{$i}rem {
			margin-#{$dir_value}:#{$i}rem!important
		}
		.pd_#{$dir_key}#{$i}em {
			padding-#{$dir_value}:#{$i}em!important
		}
		.pd_#{$dir_key}#{$i}rem {
			padding-#{$dir_value}:#{$i}rem!important
		}
	}
}

.ma_rl_auto{
	margin-left:auto!important;
	margin-right:auto!important;
}

/*　text-align　*/
.text_align_left{text-align:left!important}
.text_align_center{text-align:center!important}
.text_align_right{text-align:right!important}

/*　vertical-align　*/
.v_align_top{vertical-align:top!important}
.v_align_middle{vertical-align:middle!important}
.v_align_bottom{vertical-align:bottom!important}

/*　font　*/
.font_bold{font-weight:bold!important}
.font_normal{font-weight:normal!important}

.fontsize_10px{font-size:10px!important}
.fontsize_12px{font-size:12px!important}
.fontsize_14px{font-size:14px!important}
.fontsize_18px{font-size:18px!important}
.fontsize_16px{font-size:16px!important}
.fontsize_20px{font-size:20px!important}
.fontsize_21px{font-size:21px!important}
.fontsize_24px{font-size:24px!important}
.fontsize_0_75rem{font-size:0.75rem!important}
.fontsize_1rem{font-size:1rem!important}
.fontsize_1_25rem{font-size:1.25rem!important}
.fontsize_1_5rem{font-size:1.5rem!important}
.fontsize_1_75rem{font-size:1.75rem!important}
.fontsize_2rem{font-size:2rem!important}

/*　インデント　*/
.text_indent_0{text-indent:0!important}
.text_indent_10px{text-indent:10px!important}
.text_indent_15px{text-indent:15px!important}
.text_indent_20px{text-indent:20px!important}
.text_indent_-15px{text-indent:-15px!important}
.text_indent_-20px{text-indent:-20px!important}
.text_indent_-30px{text-indent:-30px!important}

/*　ラインヘイト　*/
.line_height_0{line-height:0!important}
.line_height_16{line-height:16px!important}
.line_height_18{line-height:18px!important}
.line_height_24{line-height:24px!important}
.line_height_32{line-height:32px!important}


/*リスト*/
.list_style_none{list-style:none!important}

/*　横幅　*/
.width_auto{width:auto!important}
@for $i from 1 through 10{
	.width_#{$i}0pa {
		width:#{$i}0%!important
	}
	.width_#{$i}em {
		width:#{$i}em!important
	}
}

/* Content Space */
$content_space_list : $content_space_pc;
@each $pm_key in $padding_margin {
	@each $content_space in $content_space_list {
		.content_space_#{$pm_key}{#{$pm_key}:#{$content_space};}
		.content_space_#{$pm_key}_top{#{$pm_key}-top:#{$content_space};}
		.content_space_#{$pm_key}_right{#{$pm_key}-right:#{$content_space};}
		.content_space_#{$pm_key}_left{#{$pm_key}-left:#{$content_space};}
		.content_space_#{$pm_key}_bottom{#{$pm_key}-bottom:#{$content_space};}
	}
}
$content_space_list : $content_space_sp;
@include max_scr ($bp_sp){
	@each $pm_key in $padding_margin {
		@each $content_space in $content_space_list {
			.content_space_#{$pm_key}{#{$pm_key}:#{$content_space};}
			.content_space_#{$pm_key}_top{#{$pm_key}-top:#{$content_space};}
			.content_space_#{$pm_key}_right{#{$pm_key}-right:#{$content_space};}
			.content_space_#{$pm_key}_left{#{$pm_key}-left:#{$content_space};}
			.content_space_#{$pm_key}_bottom{#{$pm_key}-bottom:#{$content_space};}
		}
	}
}


/* Basic Space */
$basic_space_list : $basic_space_pc;
@each $pm_key in $padding_margin {
	@each $basic_space in $basic_space_list {
		$index_key: index($basic_space_list, $basic_space);
		.basic_space#{$index_key}_#{$pm_key}{#{$pm_key}:#{$basic_space};}
		.basic_space#{$index_key}_#{$pm_key}_top{#{$pm_key}-top:#{$basic_space};}
		.basic_space#{$index_key}_#{$pm_key}_right{#{$pm_key}-right:#{$basic_space};}
		.basic_space#{$index_key}_#{$pm_key}_left{#{$pm_key}-left:#{$basic_space};}
		.basic_space#{$index_key}_#{$pm_key}_bottom{#{$pm_key}-bottom:#{$basic_space};}
	}
}
$basic_space_list : $basic_space_tb;
@include min_max_scr($bp_sp, $bp_tb){
	@each $pm_key in $padding_margin {
		@each $basic_space in $basic_space_list {
			$index_key: index($basic_space_list, $basic_space);
			.basic_space#{$index_key}_#{$pm_key}{#{$pm_key}:#{$basic_space};}
			.basic_space#{$index_key}_#{$pm_key}_top{#{$pm_key}-top:#{$basic_space};}
			.basic_space#{$index_key}_#{$pm_key}_right{#{$pm_key}-right:#{$basic_space};}
			.basic_space#{$index_key}_#{$pm_key}_left{#{$pm_key}-left:#{$basic_space};}
			.basic_space#{$index_key}_#{$pm_key}_bottom{#{$pm_key}-bottom:#{$basic_space};}
		}
	}
}
$basic_space_list : $basic_space_sp;
@media (map-get($disp_size,'sp')){
	@each $pm_key in $padding_margin {
		@each $basic_space in $basic_space_list {
			$index_key: index($basic_space_list, $basic_space);
			.basic_space#{$index_key}_#{$pm_key}{#{$pm_key}:#{$basic_space};}
			.basic_space#{$index_key}_#{$pm_key}_top{#{$pm_key}-top:#{$basic_space};}
			.basic_space#{$index_key}_#{$pm_key}_right{#{$pm_key}-right:#{$basic_space};}
			.basic_space#{$index_key}_#{$pm_key}_left{#{$pm_key}-left:#{$basic_space};}
			.basic_space#{$index_key}_#{$pm_key}_bottom{#{$pm_key}-bottom:#{$basic_space};}
		}
	}
}

//pcの場合のクラス名はpc_***
//spの場合のクラス名はsp_***
@each $disp_key, $disp_value in $disp_size {
	@media (#{$disp_value}){
		/* CSS Document */
		.#{$disp_key}_word_break_all{word-break: break-all!important}
		.#{$disp_key}_float_left{float:left!important}
		.#{$disp_key}_float_right{float:right!important}
		.#{$disp_key}_clear_both{clear:both!important}

		/*　ボーダー関連　*/
		.#{$disp_key}_border_none{border:none!important}
		/*　display関連　*/
		.#{$disp_key}_display_block{display:block!important}
		.#{$disp_key}_display_none{display:none!important}
		.#{$disp_key}_display_inlineblock{display:inline-block!important}

		/*flex関連　*/
		.#{$disp_key}_display_flex{display: flex!important}
		.#{$disp_key}_justify_start{justify-content: flex-start!important}
		.#{$disp_key}_justify_center{justify-content: center!important}
		.#{$disp_key}_justify_end{justify-content: flex-end!important}
		.#{$disp_key}_justify_around{justify-content: space-around!important}
		.#{$disp_key}_justify_between{justify-content: space-between!important}
		.#{$disp_key}_align_items_start{align-items: flex-start!important}
		.#{$disp_key}_align_items_center{align-items: center!important}
		.#{$disp_key}_align_items_end{align-items: flex-end!important}
		.#{$disp_key}_flex_wrap_wrap{flex-wrap: wrap!important}
		.#{$disp_key}_flex_wrap_nowrap{flex-wrap: nowrap!important}


		/*　その他　*/
		.#{$disp_key}_pointer_none{pointer-events: none!important}
		.#{$disp_key}_overflow-hidden{overflow:hidden!important}
		.#{$disp_key}_overflow-auto{overflow:auto!important}
		.#{$disp_key}_overflow-xscroll{overflow-x: scroll!important}
		.#{$disp_key}_white_space_nowrap{white-space:nowrap!important}
		.#{$disp_key}_white_space_normal{white-space:normal!important}
		.#{$disp_key}_align_item_flex_start{align-items: flex-start!important}
		.#{$disp_key}_align_item_center{align-items: center!important}
		.#{$disp_key}_align_item_flex_end{align-items: flex-end!important}

		/*　position　*/
		.#{$disp_key}_position_static{position: static!important}
		.#{$disp_key}_position_relative{position: relative!important}
		.#{$disp_key}_position_absolute{position: absolute!important}
		.#{$disp_key}_position_fixed{position: fixed!important}

		/*　margin padding　*/	
		@each $px_val in $px_size {
			.#{$disp_key}_ma_#{$px_val} {
				margin:$px_val!important
			}
		}
		@each $dir_key, $dir_value in $direction {
			@each $px_val in $px_size {
				.#{$disp_key}_ma_#{$dir_key}#{$px_val} {
					margin-#{$dir_value}:$px_val!important
				}
				.#{$disp_key}_pd_#{$dir_key}#{$px_val} {
					padding-#{$dir_value}:$px_val!important
				}
			}
			@for $i from 1 through 10{
				.#{$disp_key}_ma_#{$dir_key}#{$i}em {
					margin-#{$dir_value}:#{$i}em!important
				}
				.#{$disp_key}_ma_#{$dir_key}#{$i}rem {
					margin-#{$dir_value}:#{$i}rem!important
				}
				.#{$disp_key}_pd_#{$dir_key}#{$i}em {
					padding-#{$dir_value}:#{$i}em!important
				}
				.#{$disp_key}_pd_#{$dir_key}#{$i}rem {
					padding-#{$dir_value}:#{$i}rem!important
				}
			}
		}

		.#{$disp_key}_ma_rl_auto{
			margin-left:auto!important;
			margin-right:auto!important;
		}
		/*　text-align　*/
		.#{$disp_key}_text_align_left{text-align:left!important}
		.#{$disp_key}_text_align_center{text-align:center!important}
		.#{$disp_key}_text_align_right{text-align:right!important}
		/*　vertical-align　*/
		.#{$disp_key}_v_align_top{vertical-align:top!important}
		.#{$disp_key}_v_align_middle{vertical-align:middle!important}
		.#{$disp_key}_v_align_bottom{vertical-align:bottom!important}
		/*　フォントウェイト　*/
		.#{$disp_key}_font_bold{font-weight:bold!important}
		.#{$disp_key}_font_normal{font-weight:normal!important}
		/*　フォントサイズ　*/
		.#{$disp_key}_fontsize_10px{font-size:10px!important}
		.#{$disp_key}_fontsize_12px{font-size:12px!important}
		.#{$disp_key}_fontsize_14px{font-size:14px!important}
		.#{$disp_key}_fontsize_18px{font-size:18px!important}
		.#{$disp_key}_fontsize_16px{font-size:16px!important}
		.#{$disp_key}_fontsize_20px{font-size:20px!important}
		.#{$disp_key}_fontsize_21px{font-size:21px!important}
		.#{$disp_key}_fontsize_24px{font-size:24px!important}
		.#{$disp_key}_fontsize_0_75rem{font-size:0.75rem!important}
		.#{$disp_key}_fontsize_1rem{font-size:1rem!important}
		.#{$disp_key}_fontsize_1_25rem{font-size:1.25rem!important}
		.#{$disp_key}_fontsize_1_5rem{font-size:1.5rem!important}
		.#{$disp_key}_fontsize_1_75rem{font-size:1.75rem!important}
		.#{$disp_key}_fontsize_2rem{font-size:2rem!important}
		/*　インデント　*/

		.#{$disp_key}_text_indent_10px{text-indent:10px!important}
		.#{$disp_key}_text_indent_15px{text-indent:15px!important}
		.#{$disp_key}_text_indent_20px{text-indent:20px!important}
		.#{$disp_key}_text_indent_-15px{text-indent:-15px!important}
		.#{$disp_key}_text_indent_-20px{text-indent:-20px!important}
		.#{$disp_key}_text_indent_-30px{text-indent:-30px!important}
		/*　ラインヘイト　*/

		.#{$disp_key}_line_height_0{line-height:0!important}
		.#{$disp_key}_line_height_16{line-height:16px!important}
		.#{$disp_key}_line_height_18{line-height:18px!important}
		.#{$disp_key}_line_height_24{line-height:24px!important}
		.#{$disp_key}_line_height_32{line-height:32px!important}
		/*リスト*/
		.#{$disp_key}_list_style_none{list-style:none!important}
		/*　横幅　*/
		.#{$disp_key}_width_auto{width:auto!important}
		@for $i from 1 through 10{
			.#{$disp_key}_width_#{$i}0pa {
				width:#{$i}0%!important
			}
			.#{$disp_key}_width_#{$i}em {
				width:#{$i}em!important
			}
		}
	}
}